import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import fretlinkOffices from "../images/fretlink-offices.jpg";

import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import fretlinkDeskFromTop from "../images/fretlink-desk-from-top.jpg";

import carDashboardSquared from "../images/car-dashboard-squared.jpg";
import smallerTruckLandscape from "../images/smaller-truck-landscape.svg";

const AboutUsPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("about-us.html-title")} />
      <div className="fl-floating-image-bubble fl-floating-image-bubble--left">
        <img src={fretlinkDeskFromTop} alt="" />
      </div>
      <div className="fl-grid fl-top-element" style={{ marginTop: "150px" }}>
        <h1 className="fl-big-header fl-indented">
          <Trans i18nKey="about-us.title" />
        </h1>
      </div>
      <div className="fl-floating-image-bubble">
        <img src={carDashboardSquared} alt="" />
      </div>
      <div className="fl-grid" style={{ marginTop: "30px" }}>
        <div className="fl-first-half fl-green-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="about-us.first-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="about-us.first-block.title" />
            </h2>
            <Trans parent="p" i18nKey="about-us.first-block.p-we-are-commissionnaire" />
            <Trans parent="p" i18nKey="about-us.first-block.p-we-are-partners" />
          </div>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "130px" }}>
        <div className="fl-first-third fl-let-overlfow-on-left">
          <img src={smallerTruckLandscape} alt="" style={{ marginTop: "-40px" }} />
        </div>
        <div className="fl-second-half fl-blue-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="about-us.second-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="about-us.second-block.title" />
            </h2>
            <Trans parent="p" i18nKey="about-us.second-block.p-there-are-a-lot-of-opportunities" />
            <Trans parent="p" i18nKey="about-us.second-block.p-more-a-truck" />
          </div>
        </div>
      </div>
      <div
        className="fl-lighter-blue-wrapper"
        style={{ marginTop: "120px", paddingTop: "100px", paddingBottom: "100px" }}>
        <div className="fl-grid">
          <div
            className="fl-pan fl-grid"
            style={{
              minHeight: "300px",
              backgroundImage:
                "linear-gradient(-90deg, rgb(0, 36, 71) 38%, rgba(0, 36, 71, 0.3) 100%)",
            }}>
            <img src={fretlinkOffices} alt="" className="fl-pan-bg" loading="lazy" />
            <div className="fl-medium-block fl-second-half fl-vertical-center">
              <h2>
                <Trans i18nKey="about-us.contact-us.title" />
              </h2>
              <p style={{ marginTop: "24px" }}>
                <Trans i18nKey="about-us.contact-us.p-if-you-want-to-join-our-team" />
              </p>
              <p className="fl-link-next" style={{ marginBottom: 0 }}>
                <a href="https://www.welcometothejungle.com/fr/companies/fretlink">
                  <Trans i18nKey="about-us.contact-us.contact-us" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
